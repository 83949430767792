import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from "@material-ui/icons/Delete";
import {
    Paper,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
    Box,
    Typography
} from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import parse from "html-react-parser";
import ReplyIcon from '@material-ui/icons/Reply';
import ReplyAllIcon from '@material-ui/icons/ReplyAll';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { useSnackbar } from "notistack";
import MessageService from "../../services/MessageService";
import LocalStorageService from "../../services/LocalStorageService";
import GenericModal from "../shared/GenericModal";


const formatDateTime = (value) => new Intl.DateTimeFormat('en-US',
                               {
                                   year: 'numeric',
                                   month: '2-digit',
                                   day: '2-digit',
                                   hour: '2-digit',
                                   minute: '2-digit',
                                   second: '2-digit'
                               }).format(value);

const columns = [
  {
    id: 'datetime',
    label: 'Date',
    minWidth: 170,
    align: 'right',
    format: (value) => formatDateTime(value)
  },
  { id: 'from', label: 'From', minWidth: 170 },
  { id: 'to', label: 'To', minWidth: 100 },
  { id: 'subject', label: 'Subject', minWidth: 170 },
  {
    id: 'action',
    label: 'Action',
    align: 'center'
  }
];

const useStyles = makeStyles({
  root: {
    width: '98%',
    margin: '20px'
  },
  container: {
    maxHeight: 440,
  },
  body: {
    margin: '20px',
    width: '100%',
    padding: '0 40px 20px 0'
  },
  button: {
    margin: '5px 0 0 5px'
  },
  label: {
    marginLeft: '20px'
  },
  row: {
    cursor: 'pointer'
  }
});

function Messaging() {
  const classes = useStyles();
  const history = useHistory();

  const { enqueueSnackbar } = useSnackbar();
  const [replyRows, setReplyRows] = React.useState([]);
  const [body, setBody] = React.useState('');
  const [message, setMessage] = React.useState({});
  const [isToken, setIsToken] = useState(false);
  const intl = useIntl();

  const handleReply = (all, row) => {
    const sendTo = all ?
            row.to.indexOf(row.from) === -1 ? row.from + ";" + row.to : row.to
        : row.from;
    history.push({
         pathname: "/messaging-new",
         params: {
           replyOf: row.id,
           from: row.emailToken !== null ? row.emailToken : row.from,
           subject: row.subject,
           to: sendTo
         },
       });
  };

  const handleReturn = () => {
    history.push("/dashboard");
  };

  const downloadFile = (fileName, fileContent) => {
      const linkSource = "data:application/octet-stream;base64," + fileContent;
      const downloadLink = document.createElement("a");
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();

      document.body.removeChild(downloadLink);
  };

  const handleAttachmentClick = (id) => {
    MessageService.getFileById(id).then(
      (response) => {
        const file = response.data;
        downloadFile(file.name, file.content);
      },
      (error) => {
        enqueueSnackbar(intl.formatMessage({ id: "messaging.file.error" }), {
                    variant: "error",
                });
      });
  };

  const toAttachmentChip = (attachment) => {
    return (
        <Chip
          style={{ marginLeft: '10px' }}
          icon={<AttachFileIcon />}
          label={attachment.name}
          onClick={() => handleAttachmentClick(attachment.id)}
        />
    );
  };

  const makeHeader = (row) => {
    const attachments = row.attachments.map((a) => toAttachmentChip(a));
    return <>
        <br />
        <b>From: </b>{row.from}
        <br />
        <b>Sent: </b>{formatDateTime(row.datetime)}
        <br />
        <b>To: </b>{row.to}
        <br />
        <b>Subject: </b>{row.subject}
        <br />
        <b>Attachments: </b>{attachments}
        <br />
        <br />
    </>;

  }

  const addMessageToBody = (row) => {
        const header = makeHeader(row);
        return <>
            {header}
            {row.body}
            <br />
            <hr />
            <br />
            <br />
            {body}
        </>
  };

  const getRepliesForMessage = (initialMessageBody, id) => {
    MessageService.getMessagesByReplyOf(id).then(
      (response) => {
        const { data } = response;
        let newBody = [];
        if (data.length > 0)
             data.forEach((row) => { newBody.push(addMessageToBody(row)) });
        newBody.push(initialMessageBody);
        setBody(<>{newBody}</>);
      },
      (error) => {
        enqueueSnackbar(
          intl.formatMessage(
            { id: "error.unknown" },
            { errorMessage: error?.message }
          ),
          { variant: "error" }
        );
      }
    );
  }

  const getMessageById = (id, emailToken) => {
    MessageService.getMessagesById(id).then(
      (response) => {
        const { data } = response;
        const row = {
            id: data.id,
            from: data.from,
            to: data.to,
            cc: data.cc,
            bcc: data.bcc,
            subject: data.subject,
            datetime: data.datetime,
            body: data.body,
            attachments: data.attachments,
            emailToken
          };
        setMessage(row);
        getRepliesForMessage(addMessageToBody(row), data.id);
      },
      (error) => {
        enqueueSnackbar(
          intl.formatMessage(
            { id: "error.unknown" },
            { errorMessage: error?.message }
          ),
          { variant: "error" }
        );
      }
    );
  }

  const getMessageByToken = (token) => {
      MessageService.getMessagesByToken(token).then(
        (response) => {
          const { data } = response;
          getMessageById(data.replyOf !== null ? data.replyOf : data.id, data.emailToken);
        },
        (error) => {
          enqueueSnackbar(
            intl.formatMessage(
              { id: "error.unknown" },
              { errorMessage: error?.message }
            ),
            { variant: "error" }
          );
        }
      );
    }

  const getMessages = useCallback(() => {

      const accessToken = LocalStorageService.getAccessToken();
      if (accessToken === null) setIsToken(true);
      if (history.location.pathname.indexOf("/message/id") === 0) {
        const id = history.location.pathname.replace("/message/id/", "");
        getMessageById(id, null);
      } else {
        const token = history.location.pathname.replace("/message/token/", "");
        getMessageByToken(token);
      }
    }, []);

  useEffect(async () => {
    getMessages();
  }, [getMessages]);

  return (
  <>
    <Box width="100%" marginTop="20px">
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="flex-end"
      mr={2}
    >
      {!isToken ? (
          <Button
            variant="contained"
            color="secondary"
            className={classes.button}
            onClick={() => handleReturn()}
          >
            <FormattedMessage id="messaging.return" />
          </Button>) : (<></>)}
          <Button
            variant="contained"
            color="secondary"
            className={classes.button}
            startIcon={<ReplyAllIcon />}
            onClick={() => handleReply(true, message)}
          >
            <FormattedMessage id="messaging.replyAll" />
          </Button>
    </Box>

    <Paper className={classes.root}>
        <div
          className={classes.body}
          id="body"
        >{body}</div>
    </Paper>
  </Box>
  </>
  );
}

export default Messaging;
